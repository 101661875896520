<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                v-if="getUrlParameter('tesouraria')"
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/tesouraria'
                "
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >Modo tesouraria</a>
              <a
                v-else
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria'
                "
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Pagamento</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Configurações de
                  <span>pagamento</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->

              <section class="mt-4">
                <b-tabs>

                  <!-- Planos -->
                  <b-tab active>
                    <template #title>
                      Planos de pagamento
                    </template>
                    <div class="row">
                      <div class="col-12">
                        <div class="card mb-4">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                                <button
                                  class="btn btn-sm btn-primary"
                                  @click.prevent="showModal('modalCriarPlanoPagamento')"
                                >
                                  <small>+ Criar plano</small>
                                </button>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12 table-responsive mt-2">
                                <table class="table table-sm">
                                  <thead class="thead-dark">
                                    <tr class="text-center">
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Nome do plano</small>
                                      </th>
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Periodicidade</small>
                                      </th>
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Dia/mês</small>
                                      </th>
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Tempo determinado</small>
                                      </th>
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Ativo</small>
                                      </th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Ações</small>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="fastSecretariaPlanoPagamentoLoading">
                                    <tr>
                                      <td
                                        colspan="6"
                                        class="text-center"
                                        v-html="fastLoadingDados()"
                                      />
                                    </tr>
                                  </tbody>
                                  <tbody v-else-if="fastSecretariaPlanoPagamento.length">
                                    <tr
                                      v-for="plano in fastSecretariaPlanoPagamento"
                                      :key="plano.id_plano_pagamento"
                                    >
                                      <td class="align-middle text-center">
                                        {{ plano.nome_plano }}
                                      </td>   
                                      <td class="align-middle text-center">
                                        {{ plano.periocidade_cada_unidade }}
                                      </td> 
                                      <td class="align-middle text-center">
                                        <span v-if="plano.dia_mes == 'D'">Dia</span>
                                        <span v-else-if="plano.dia_mes == 'M'">Mês</span>
                                      </td> 
                                      <td class="align-middle text-center">
                                        <div v-if="plano.dia_mes == 'M'">
                                          <span v-if="plano.tempo_determinado == '0'">Indeterminado</span>
                                          <span v-else-if="plano.tempo_determinado == '1'">1 mês</span>
                                          <span v-else>{{ plano.tempo_determinado }} meses</span>                                    
                                        </div>
                                        <div v-else>
                                          <span v-if="plano.tempo_determinado == '0'">Indeterminado</span>
                                          <span v-else-if="plano.tempo_determinado == '1'">1 dia</span>
                                          <span v-else>{{ plano.tempo_determinado }} dias</span>    
                                        </div>                                  
                                      </td> 
                                      <td class="align-middle text-center">
                                        <span
                                          v-if="plano.ativo"
                                          class="btn btn-sm btn-success pt-0 pb-0"
                                        ><small>Sim</small></span>
                                        <span
                                          v-else
                                          class="btn btn-sm btn-danger pt-0 pb-0"
                                        ><small>Não</small></span>
                                      </td>                              
                                      <td class="text-center align-middle">                                  
                                        <button
                                          class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                          @click="exibeModalEditarPlanoPagamento(plano)"
                                        >
                                          <small>Editar</small>
                                        </button>
                                        <button
                                          class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                          @click="exibeModalExcluirPlanoPagamento(plano)"
                                        >
                                          <small>Excluir</small>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td
                                        colspan="6"
                                        class="text-center"
                                      >
                                        Nenhum plano cadastrado
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <!-- Plano de contas -->
                  <b-tab>
                    <template #title>
                      Planos de conta
                    </template>
                    <div class="row">
                      <div class="col-12">
                        <div class="card mb-4">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                                <button
                                  class="btn btn-sm btn-primary"
                                  @click.prevent="showModal('modalCriarPlanoConta')"
                                >
                                  <small>+ Criar plano de conta</small>
                                </button>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12 table-responsive mt-2">
                                <table class="table table-sm">
                                  <thead class="thead-dark">
                                    <tr class="text-center">
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Nome da conta</small>
                                      </th>
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Redutor</small>
                                      </th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Ações</small>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="fastTesourariaPlanoContasLoading">
                                    <tr>
                                      <td
                                        colspan="3"
                                        class="text-center"
                                      >
                                      Carregando...
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else-if="fastTesourariaPlanoContas.length">
                                    <tr
                                      v-for="e in fastTesourariaPlanoContas"
                                      :key="e.id_plano_conta"
                                    >
                                      <td class="align-middle text-center">
                                        {{ e.nome_conta }}
                                      </td>   
                                      <td class="align-middle text-center">
                                        <span v-if="e.redutor" class="text-success">Sim</span>
                                        <span v-else class="text-danger">Não</span>
                                      </td>             
                                      <td class="text-center align-middle">                                  
                                        <button
                                          class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                          @click="exibeModalEditarPlanoConta(e)"
                                        >
                                          <small>Editar</small>
                                        </button>
                                        <button
                                          class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                          @click="exibeModalExcluirPlanoConta(e)"
                                        >
                                          <small>Excluir</small>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td
                                        colspan="3"
                                        class="text-center"
                                      >
                                        Nenhuma conta cadastrada
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <!-- Tipo pagamento -->
                  <b-tab>
                    <template #title>
                      Tipos de pagamento
                    </template>
                    <div class="row">
                      <div class="col-12">
                        <div class="card mb-4">
                          <div class="card-body">
                            <div class="row">                              
                              <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                                <button
                                  class="btn btn-sm btn-primary"
                                  @click.prevent="showModal('modalCriarTipoPagamento')"
                                >
                                  <small>+ Criar tipo de pagamento</small>
                                </button>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12 table-responsive mt-2">
                                <table class="table table-sm">
                                  <thead class="thead-dark">
                                    <tr class="text-center">
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Nome do tipo de pagamento</small>
                                      </th>
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Operador</small>
                                      </th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Ações</small>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="fastSecretariaTipoPagamentoLoading">
                                    <tr>
                                      <td
                                        colspan="3"
                                        class="text-center"
                                        v-html="fastLoadingDados()"
                                      />
                                    </tr>
                                  </tbody>
                                  <tbody v-else-if="fastSecretariaTipoPagamento.length">
                                    <tr
                                      v-for="pagamento in fastSecretariaTipoPagamento"
                                      :key="pagamento.id_tipo_pagamento"
                                    >
                                      <td class="align-middle text-center">
                                        {{ pagamento.nome_tipo_pagamento }}
                                      </td>   
                                      <td class="align-middle text-center">
                                        <span v-if="pagamento.operador == 'D'">Débito</span>
                                        <span v-else-if="pagamento.operador == 'C'">Crédito</span>
                                        <span v-else>Qualquer Operador</span>
                                      </td>             
                                      <td class="text-center align-middle">                                  
                                        <button
                                          class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                          @click="exibeModalEditarTipoPagamento(pagamento)"
                                        >
                                          <small>Editar</small>
                                        </button>
                                        <!-- <button
                                          class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                          @click="exibeModalExcluirTipoPagamento(pagamento)"
                                        >
                                          <small>Excluir</small>
                                        </button> -->
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td
                                        colspan="3"
                                        class="text-center"
                                      >
                                        Nenhum tipo de pagamento cadastrado
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <!-- Conta -->
                  <b-tab>
                    <template #title>
                      Contas
                    </template>
                    <div class="row">
                      <div class="col-12">
                        <div class="card mb-4">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                                <button
                                  class="btn btn-sm btn-primary"
                                  @click.prevent="showModal('modalCriarContaBanco')"
                                >
                                  <small>+ Criar conta</small>
                                </button>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12 table-responsive mt-2">
                                <table class="table table-sm">
                                  <thead class="thead-dark">
                                    <tr class="text-center">
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Código do banco</small>
                                      </th>
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Agência</small>
                                      </th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Ações</small>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="fastTesourariaContaBancoLoading">
                                    <tr>
                                      <td
                                        colspan="3"
                                        class="text-center"
                                      >
                                      Carregando...
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else-if="fastTesourariaContaBanco.length">
                                    <tr
                                      v-for="e in fastTesourariaContaBanco"
                                      :key="e.id_conta_banco"
                                    >
                                      <td class="align-middle text-center">
                                        {{ e.codigo_banco }}
                                      </td>   
                                      <td class="align-middle text-center">
                                        {{e.agencia}}
                                      </td>             
                                      <td class="text-center align-middle">                                  
                                        <button
                                          class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                          @click="exibeModalEditarContaBanco(e)"
                                        >
                                          <small>Editar</small>
                                        </button>
                                        <button
                                          class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                          @click="exibeModalExcluirContaBanco(e)"
                                        >
                                          <small>Excluir</small>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td
                                        colspan="3"
                                        class="text-center"
                                      >
                                        Nenhuma conta cadastrada
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <!-- Conta -->
                  <b-tab>
                    <template #title>
                      Centro de custo
                    </template>
                    <div class="row">
                      <div class="col-12">
                        <div class="card mb-4">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                                <button
                                  class="btn btn-sm btn-primary"
                                  @click.prevent="showModal('modalCriarCentoCusto')"
                                >
                                  <small>+ Criar centro de custo</small>
                                </button>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12 table-responsive mt-2">
                                <table class="table table-sm">
                                  <thead class="thead-dark">
                                    <tr class="text-center">
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Nome do centro de custo</small>
                                      </th>
                                      <th class="text-center">
                                        <small class="font-weight-bold">Ações</small>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="fastTesourariaCentoCustoLoading">
                                    <tr>
                                      <td
                                        colspan="2"
                                        class="text-center"
                                      >
                                      Carregando...
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else-if="fastTesourariaCentoCusto.length">
                                    <tr
                                      v-for="e in fastTesourariaCentoCusto"
                                      :key="e.id_cento_custo"
                                    >
                                      <td class="align-middle text-center">
                                        {{ e.nome_cento_custo }}
                                      </td>   
                                      <td class="text-center align-middle">                                  
                                        <button
                                          class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                          @click="exibeModalEditarCentoCusto(e)"
                                        >
                                          <small>Editar</small>
                                        </button>
                                        <button
                                          class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                          @click="exibeModalExcluirCentoCusto(e)"
                                        >
                                          <small>Excluir</small>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td
                                        colspan="2"
                                        class="text-center"
                                      >
                                        Nenhum centro de custo cadastrado
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <!-- Taxas e Acordos -->
                  <b-tab>
                    <template #title>
                      Taxas e acordos
                    </template>
                    <div class="row">
                      <div class="col-12">
                        <div class="card mb-4">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                                <a
                                  class="btn btn-sm btn-primary text-white"
                                  :href="
                                    '/plataforma/' +
                                      $route.params.id_plataforma +
                                      '/admin/gateways-pagamento'
                                  "
                                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/gateways-pagamento')"
                                >
                                  <small>+ Alterar configurações de Gateways de Pagamento</small>
                              </a>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12 table-responsive mt-2">
                                <table class="table table-sm">
                                  <thead class="thead-dark">
                                    <tr class="text-center">
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Nome do Gateway</small>
                                      </th>
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Ações</small>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="fastTesourariaGatewaysPagamento.length">
                                    <tr
                                      v-for="e in fastTesourariaGatewaysPagamento"
                                      :key="e.id_pagamento_gateway"
                                    >
                                      <td class="align-middle text-center">
                                        {{ e.nome_gateway }}
                                      </td>   
                                      <td class="text-center align-middle">                                  
                                        <button
                                          class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                          @click="exibeModalConfigurarTaxasEAcordos(e)"
                                        >
                                          <small>Configurar Taxas</small>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td
                                        colspan="2"
                                        class="text-center"
                                      >
                                        Nenhum gateway de pagamento disponível
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <!-- Parcelamentos nos Gateway de Pagamento -->
                  <b-tab>
                    <template #title>
                      Parcelamentos nos Gateways de Pagamentos
                    </template>
                    <div class="row">
                      <div class="col-12">
                        <div class="card mb-4">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                                <a
                                  class="btn btn-sm btn-primary text-white"
                                  :href="
                                    '/plataforma/' +
                                      $route.params.id_plataforma +
                                      '/admin/gateways-pagamento'
                                  "
                                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/gateways-pagamento')"
                                >
                                  <small>+ Alterar configurações de Gateways de Pagamento</small>
                              </a>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12 table-responsive mt-2">
                                <table class="table table-sm">
                                  <thead class="thead-dark">
                                    <tr class="text-center">
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Nome do Gateway</small>
                                      </th>
                                      <th>
                                        <small
                                          class="font-weight-bold"
                                        >Ações</small>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody v-if="fastTesourariaGatewaysPagamento.length">
                                    <tr
                                      v-for="e in fastTesourariaGatewaysPagamento"
                                      :key="e.id_pagamento_gateway"
                                    >
                                      <td class="align-middle text-center">
                                        {{ e.nome_gateway }}
                                      </td>   
                                      <td class="text-center align-middle">                                  
                                        <button
                                          class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                          @click="exibeModalConfigurarOpcoesDeParcelamento(e)"
                                        >
                                          <small>Configurar Opções de Parcelamento</small>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td
                                        colspan="2"
                                        class="text-center"
                                      >
                                        Nenhum gateway de pagamento disponível
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>

                </b-tabs>
              </section>
              

              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  v-if="getUrlParameter('tesouraria')"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/tesouraria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
                ><b-icon-arrow-return-left />
                  Voltar para menu</a>
                <a
                  v-else
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarPlanoPagamento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo plano</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarPlanoPagamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Nome do plano</label>
              <input
                v-model="fastSecretariaPlanoPagamentoNovo.nome_plano"
                type="text"
                class="form-control"
                maxlength="100"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Periodicidade</label>
              <select
                v-if="fastSecretariaPlanoPagamentoNovo.dia_mes == 'D'"
                v-model="fastSecretariaPlanoPagamentoNovo.periocidade_cada_unidade"
                class="form-control"
              >    
                <option
                  v-for="dia in 31"
                  :key="dia"
                  :value="dia"
                >
                  <span v-if="dia == 1">Todo dia</span> 
                  <span v-else>A cada {{ dia }} dias</span>                  
                </option>
              </select>
              <select
                v-else
                v-model="fastSecretariaPlanoPagamentoNovo.periocidade_cada_unidade"
                class="form-control"
              >
                <option
                  v-for="mes in 12"
                  :key="mes"
                  :value="mes"
                >
                  <span v-if="mes == 1">Todo mês</span>      
                  <span v-else>A cada {{ mes }} meses</span>                  
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Dia/Mês</label>
              <select
                v-model="fastSecretariaPlanoPagamentoNovo.dia_mes"
                class="form-control"
                @change="resetaPeriodicidadeTempo()"
              >
                <option value="D">
                  Dia
                </option>
                <option value="M">
                  Mês
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Tempo determinado</label>
              <select
                v-if="fastSecretariaPlanoPagamentoNovo.dia_mes == 'D'"
                v-model="fastSecretariaPlanoPagamentoNovo.tempo_determinado"
                class="form-control"
              >                
                <option
                  value="0"
                  selected
                >
                  Indeterminado
                </option>                
                <option
                  v-for="dia in 31"
                  :key="dia"
                  :value="dia.toString()"
                >
                  {{ dia }} dias
                </option>
              </select>
              <select
                v-else
                v-model="fastSecretariaPlanoPagamentoNovo.tempo_determinado"
                class="form-control"
              >                
                <option
                  value="0"
                  selected
                >
                  Indeterminado
                </option>                
                <option
                  v-for="mes in 12"
                  :key="mes"
                  :value="mes.toString()"
                >
                  {{ mes }} meses
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Observações</label>
              <textarea
                v-model="fastSecretariaPlanoPagamentoNovo.obs"
                class="form-control"
              />
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 mb-2 text-center">
              <label>Ativo</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-model="fastSecretariaPlanoPagamentoNovo.ativo"
                        checked="checked"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarSecretariaPlanoPagamento()"
                >
                  Criar plano
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarTipoPagamento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo tipo de pagamento</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarTipoPagamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Nome do tipo pagamento</label>
              <input
                v-model="fastSecretariaTipoPagamentoNovo.nome_tipo_pagamento"
                type="text"
                class="form-control"
                maxlength="200"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Operador</label>
              <select
                v-model="fastSecretariaTipoPagamentoNovo.operador"
                class="form-control"
                disabled
              >
                <option value="">
                  Selecione a forma
                </option>
                <option :value="fastSecretariaTipoPagamentoNovo.operador">
                  {{ fastSecretariaTipoPagamentoNovo.nomeOperador }}
                </option>
              </select>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Plano de conta</label>
              <select class="form-control" v-model="fastSecretariaTipoPagamentoNovo.id_plano_conta">
                <option value="0">Nenhum plano selecionado</option>
                <option v-for="(e, index) in fastTesourariaPlanoContas" :value="e.id_plano_conta" :key="index">
                  {{e.nome_conta}}
                </option>
              </select>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Forma de pagamento</label>
              <select
                v-model="fastSecretariaTipoPagamentoNovo.forma_pagamento"
                class="form-control"
                @change="modificaOperador"

              >
                <option value="">
                  Selecione a forma de pagamento
                </option>
                <option v-for="forma in fastSecretariaFormaPagamentoArray" :key="forma.id_forma_pagamento" :value="forma.forma_pagamento_sigla">
                  {{forma.forma_pagamento_nome}}
                </option>
              </select>
            </div>

            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarSecretariaTipoPagamento()"
                >
                  Criar tipo de pagamento
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarContaBanco"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Nova conta</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarContaBanco')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Código do banco <span class="text-danger">*</span></label>
              <input
                v-model="fastTesourariaContaBancoNovo.codigo_banco"
                type="text"
                :class="fastTesourariaContaBancoNovo.codigo_banco ? 'form-control' : 'form-control border border-danger text-danger'"
                maxlength="4"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Agência <span class="text-danger">*</span></label>
              <input
                v-model="fastTesourariaContaBancoNovo.agencia"
                type="text"
                :class="fastTesourariaContaBancoNovo.agencia ? 'form-control' : 'form-control border border-danger text-danger'"
                maxlength="20"
              >
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>CNPJ do banco <span class="text-danger">*</span></label>
              <input
                v-model="fastTesourariaContaBancoNovo.cnpj_banco"
                v-mask="['##.###.###/####-##']"
                type="text"
                :class="fastTesourariaContaBancoNovo.cnpj_banco ? 'form-control' : 'form-control border border-danger text-danger'"
                maxlength="20"
              >
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Nome razão do banco<span class="text-danger">*</span></label>
              <input
                v-model="fastTesourariaContaBancoNovo.razao_banco"
                type="text"
                :class="fastTesourariaContaBancoNovo.razao_banco ? 'form-control' : 'form-control border border-danger text-danger'"
                maxlength="20"
              >
            </div>

            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarTesourariaContaBanco()"
                >
                  Cadastrar conta
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarPlanoConta"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Nova plano de conta</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarPlanoConta')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Nome da conta <span class="text-danger">*</span></label>
              <input
                v-model="fastTesourariaPlanoContaNovo.nome_conta"
                type="text"
                :class="fastTesourariaPlanoContaNovo.nome_conta ? 'form-control' : 'form-control border border-danger text-danger'"
                maxlength="200"
              >
            </div>           

            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center mb-1">
                        <span>Redutor</span>
                      </div>
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastTesourariaPlanoContaNovo.redutor"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarTesourariaPlanoConta()"
                >
                  Cadastrar plano de conta
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarCentoCusto"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo centro de custo</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarCentoCusto')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Nome do centro de custo <span class="text-danger">*</span></label>
              <input
                v-model="fastTesourariaCentoCustoNovo.nome_cento_custo"
                type="text"
                :class="fastTesourariaCentoCustoNovo.nome_cento_custo ? 'form-control' : 'form-control border border-danger text-danger'"
                maxlength="200"
              >
            </div>    

            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarTesourariaCentoCusto()"
                >
                  Cadastrar centro de custo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarPlanoPagamento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar plano</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarPlanoPagamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Nome do plano</label>
              <input
                v-model="fastSecretariaPlanoPagamentoEditar.nome_plano"
                type="text"
                class="form-control"
                maxlength="100"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Periodicidade</label>
              <select
                v-if="fastSecretariaPlanoPagamentoEditar.dia_mes == 'D'"
                v-model="fastSecretariaPlanoPagamentoEditar.periocidade_cada_unidade"
                class="form-control"
              >    
                <option
                  v-for="dia in 31"
                  :key="dia"
                  :value="dia"
                >
                  <span v-if="dia == 1">Todo dia</span> 
                  <span v-else>A cada {{ dia }} dias</span>                  
                </option>
              </select>
              <select
                v-else
                v-model="fastSecretariaPlanoPagamentoEditar.periocidade_cada_unidade"
                class="form-control"
              >
                <option
                  v-for="mes in 12"
                  :key="mes"
                  :value="mes"
                >
                  <span v-if="mes == 1">Todo mês</span>      
                  <span v-else>A cada {{ mes }} meses</span>                  
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Dia/Mês</label>
              <select
                v-model="fastSecretariaPlanoPagamentoEditar.dia_mes"
                class="form-control"
                @change="resetaPeriodicidadeTempo()"
              >
                <option value="D">
                  Dia
                </option>
                <option value="M">
                  Mês
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Tempo determinado</label>
              <select
                v-if="fastSecretariaPlanoPagamentoEditar.dia_mes == 'D'"
                v-model="fastSecretariaPlanoPagamentoEditar.tempo_determinado"
                class="form-control"
              >                
                <option value="0">
                  Indeterminado
                </option>                
                <option
                  v-for="dia in 31"
                  :key="dia"
                  :value="dia.toString()"
                >
                  <span v-if="dia == 1">1 dia</span>
                  <span v-else>{{ dia }} dias</span>                  
                </option>
              </select>
              <select
                v-else
                v-model="fastSecretariaPlanoPagamentoEditar.tempo_determinado"
                class="form-control"
              >                
                <option value="0">
                  Indeterminado
                </option>                
                <option
                  v-for="mes in 12"
                  :key="mes"
                  :value="mes.toString()"
                >
                  <span v-if="mes == '1'">1 mês</span>
                  <span v-else>{{ mes }} meses</span>                    
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Observações</label>
              <textarea
                v-model="fastSecretariaPlanoPagamentoEditar.obs"
                class="form-control"
              />
            </div>

            <div class="col-sm-12 col-md-12 col-lg-2 mb-2">
              <label>Ativo</label>
              <div class="fd-app-meus-cursos mt-0 ml-1">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div
                    class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center"
                  >
                    <label class="switch">
                      <input
                        v-if="fastSecretariaPlanoPagamentoEditar.ativo"
                        v-model="fastSecretariaPlanoPagamentoEditar.ativo"
                        checked="checked"
                        type="checkbox"
                      >
                      <input
                        v-else
                        v-model="fastSecretariaPlanoPagamentoEditar.ativo"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarPlanoPagamento()"
                >
                  Confirmar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarTipoPagamento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar tipo de pagamento</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarTipoPagamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Nome do tipo pagamento</label>
              <input
                v-model="fastSecretariaTipoPagamentoEditar.nome_tipo_pagamento"
                type="text"
                class="form-control"
                maxlength="200"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Operador</label>
              <select
                v-model="fastSecretariaTipoPagamentoEditar.operador"
                class="form-control"
                disabled
              >
                <option value="">
                  Selecione a forma
                </option>
                <option :value="fastSecretariaTipoPagamentoEditar.operador">
                  {{ fastSecretariaTipoPagamentoEditar.nomeOperador }}
                </option>
              </select>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Plano de conta</label>
              <select class="form-control" v-model="fastSecretariaTipoPagamentoEditar.id_plano_conta">
                <option value="0">Nenhum plano selecionado</option>
                <option v-for="(e, index) in fastTesourariaPlanoContas" :value="e.id_plano_conta" :key="index">
                  {{e.nome_conta}}
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Forma de pagamento</label>
              <select
                v-model="fastSecretariaTipoPagamentoEditar.forma_pagamento"
                class="form-control"
                @change="modificaOperadorEditar"

              >
                <option value="">
                  Selecione a forma de pagamento
                </option>
                <option v-for="forma in fastSecretariaFormaPagamentoArray" :key="forma.id_forma_pagamento" :value="forma.forma_pagamento_sigla">
                  {{forma.forma_pagamento_nome}}
                </option>
              </select>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarTipoPagamento()"
                >
                  Confirmar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarContaBanco"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar conta</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarContaBanco')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Código do banco <span class="text-danger">*</span></label>
              <input
                v-model="fastTesourariaContaBancoEditar.codigo_banco"
                type="text"
                :class="fastTesourariaContaBancoEditar.codigo_banco ? 'form-control' : 'form-control border border-danger text-danger'"
                maxlength="4"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Agência <span class="text-danger">*</span></label>
              <input
                v-model="fastTesourariaContaBancoEditar.agencia"
                type="text"
                :class="fastTesourariaContaBancoEditar.agencia ? 'form-control' : 'form-control border border-danger text-danger'"
                maxlength="20"
              >
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>CNPJ do banco <span class="text-danger">*</span></label>
              <input
                v-model="fastTesourariaContaBancoEditar.cnpj_banco"
                v-mask="['##.###.###/####-##']"
                type="text"
                :class="fastTesourariaContaBancoEditar.cnpj_banco ? 'form-control' : 'form-control border border-danger text-danger'"
                maxlength="20"
              >
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Nome razão do banco<span class="text-danger">*</span></label>
              <input
                v-model="fastTesourariaContaBancoEditar.razao_banco"
                type="text"
                :class="fastTesourariaContaBancoEditar.razao_banco ? 'form-control' : 'form-control border border-danger text-danger'"
                maxlength="20"
              >
            </div>

            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarContaBanco()"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarPlanoConta"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar plano de conta</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarPlanoConta')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Nome da conta <span class="text-danger">*</span></label>
              <input
                v-model="fastTesourariaPlanoContaEditar.nome_conta"
                type="text"
                :class="fastTesourariaPlanoContaEditar.nome_conta ? 'form-control' : 'form-control border border-danger text-danger'"
                maxlength="200"
              >
            </div>           

            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center mb-1">
                        <span>Redutor</span>
                      </div>
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastTesourariaPlanoContaEditar.redutor"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarPlanoConta()"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarCentoCusto"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar centro de custo</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarCentoCusto')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Nome do centro de custo <span class="text-danger">*</span></label>
              <input
                v-model="fastTesourariaCentoCustoEditar.nome_cento_custo"
                type="text"
                :class="fastTesourariaCentoCustoEditar.nome_cento_custo ? 'form-control' : 'form-control border border-danger text-danger'"
                maxlength="200"
              >
            </div>    

            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarCentoCusto()"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirPlanoPagamento"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir plano?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirPlanoPagamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirPlanoPagamento()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirTipoPagamento"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir tipo de pagamento?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirTipoPagamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirTipoPagamento()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirContaBanco"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir conta?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirContaBanco')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirContaBanco()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirPlanoConta"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir plano de conta?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirPlanoConta')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirPlanoConta()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirCentoCusto"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir centro de custo?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirCentoCusto')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirCentoCusto()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalConfigurarTaxasEAcordos"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-9 pl-4 pr-4">
          <h4>Configurar taxas de {{ fastTesourariaGatewayPagamentoSelecionado.nome_gateway }}</h4>
        </div>
        <div class="col-3 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConfigurarTaxasEAcordos')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 text-center my-3">
          <small v-if="fastTesourariaTaxasAcordos.length" class="btn btn-novo btn-success">Configurado</small>
          <small v-else class="btn btn-novo btn-secondary">Não configurado</small>
        </div>
        <div class="col-12 text-center">
          <label class="col-6">
           Juros mensal (em %):
          <input type="number" class="form-control" v-model="fastTesourariaTaxasAcordosSelecionado.taxa_juros_mensal">
          </label>
          <label class="col-6">
            Multa (em %):
            <input type="number" class="form-control" v-model="fastTesourariaTaxasAcordosSelecionado.multa">
          </label>
          <label class="col-6">
            Serasa (em %):
            <input type="number" class="form-control" v-model="fastTesourariaTaxasAcordosSelecionado.taxa_serasa">
          </label>
          <label class="col-6">
            Cartório (em %):
            <input type="number" class="form-control" v-model="fastTesourariaTaxasAcordosSelecionado.taxa_cartorio">
          </label>
          <label class="col-6">
            Outras taxas (em %):
            <input type="number" class="form-control" v-model="fastTesourariaTaxasAcordosSelecionado.outras_taxas">
          </label>
          <label class="col-6">
            Parceiro de cobrança (em %):
            <input type="number" class="form-control" v-model="fastTesourariaTaxasAcordosSelecionado.taxa_parceiro_cobranca">
          </label>
        </div>
        <p class="text-secondary d-flex flex-column m-3">
          <small>- Digite apenas o número, não é necessário inserir o símbolo de %</small>
        </p>
        <div v-if="fastTesourariaTaxasAcordos.length" class="col-12 d-flex justify-content-between">
          <button v-if="$store.state.fastCarregando" class="btn btn-success py-0 px-2" @click="atualizaTaxasAcordosGateway" disabled>
            <small>
              <b-spinner
                type="grow"
                label="Spinning"
              />
            </small>
          </button>
          <button v-else class="btn btn-primary" @click="atualizaTaxasAcordos">
            Atualizar
          </button>
          <button class="btn btn-danger" @click="exibeModalExcluirTaxasEAcordos">
            Remover
          </button>
        </div>
        <div v-else class="col-12 d-flex justify-content-center">
          <button v-if="$store.state.fastCarregando" class="btn btn-success py-0 px-2" @click="atualizaTaxasAcordosGateway" disabled>
            <small>
              <b-spinner
                type="grow"
                label="Spinning"
              />
            </small>
          </button>
          <button v-else-if="!fastTesourariaTaxasAcordos.length" class="btn btn-success" @click="insereTaxasAcordos">
            Incluir
          </button>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirTaxasAcordos"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-9 pl-4 pr-4">
          <h4>Excluir taxas de {{ fastTesourariaGatewayPagamentoSelecionado.nome_gateway }}</h4>
        </div>
        <div class="col-3 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirTaxasAcordos')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 m-2">
          <p>
            Deseja realmente excluir a configuração de taxas de {{ fastTesourariaGatewayPagamentoSelecionado.nome_gateway }}?
          </p>
        </div>
        <div class="col-12 d-flex justify-content-center">
          <button v-if="$store.state.fastCarregando" class="btn btn-success py-0 px-2" @click="atualizaTaxasAcordosGateway" disabled>
            <small>
              <b-spinner
                type="grow"
                label="Spinning"
              />
            </small>
          </button>
          <button v-else class="btn btn-danger py-0 px-2" @click="excluiTaxasAcordos">
            <small>
              Confirmar
            </small>
          </button>
        </div>
      </div>
    </modal>

    <modal
      name="modalConfigurarOpcoesDeParcelamento"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-9 pl-4 pr-4">
          <h4>Configurar parcelamento de {{ fastTesourariaGatewayPagamentoSelecionado.nome_gateway }}</h4>
        </div>
        <div class="col-3 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConfigurarOpcoesDeParcelamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 mt-2">
          <table class="table table-sm">
            <thead class="thead-dark">
              <tr class="text-center">
                <th>
                  <small
                    class="font-weight-bold"
                  >Nº Parcela</small>
                </th>
                <th>
                  <small
                    class="font-weight-bold"
                  >% Cobrança Cartão</small>
                </th>
                <th>
                  <small
                    class="font-weight-bold"
                  >% Cobrança Boleto</small>
                </th>
                <th>
                  <small
                    class="font-weight-bold"
                  >Atualizar</small>
                </th>
                <th>
                  <small
                    class="font-weight-bold"
                  >Remover</small>
                </th>
              </tr>
            </thead>
            <tbody v-if="fastTesourariaTaxasAcordosGateways.length">
              <tr
                v-for="e in fastTesourariaTaxasAcordosGateways"
                :key="e.id_taxa_acordo_gateway"
              >
                <td class="align-middle text-center">
                  {{ e.parcela }}
                </td>   
                <td class="text-center align-middle">                                  
                  {{ e.percentual_cobranca_cartao }}%
                </td>
                <td class="text-center align-middle">                                  
                  {{ e.percentual_cobranca_boleto }}%
                </td>
                <td class="text-center align-middle">                                  
                  <b-icon-arrow-repeat class="text-primary icon-update" @click="exibeModalConfigurarParcela('editar', e)" />
                </td>
                <td class="text-center align-middle">                                  
                  <b-icon-x-lg class="text-danger icon-remove" @click="exibeModalExcluirParcela(e)"/>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td
                  colspan="12"
                  class="text-center text-nowrap"
                >
                  Nenhuma configuração de parcelamento cadastrada
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 d-flex justify-content-center">
          <button class="btn btn-success py-0 px-2" @click="exibeModalConfigurarParcela('novo')">
            <small>
              Incluir novo parcelamento
            </small>
          </button>
        </div>
      </div>
    </modal>

    <modal
      name="modalConfigurarParcela"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-9 pl-4 pr-4">
          <h4>Configurar parcelamento de {{ fastTesourariaGatewayPagamentoSelecionado.nome_gateway }}</h4>
        </div>
        <div class="col-3 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalConfigurarParcela')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 text-center">
          <label class="col-4">
            <small>
             Número de parcela:
           </small>
           <input v-if="fastTesourariaTaxasAcordosGatewaySelecionado.id_taxa_acordo_gateway" type="number" class="form-control" v-model="fastTesourariaTaxasAcordosGatewaySelecionado.parcela" readonly>
           <input v-else type="number" class="form-control" v-model="fastTesourariaTaxasAcordosGatewaySelecionado.parcela">
          </label>
          <label class="col-4">
            <small>
              Taxa Cartão (em %):
            </small>
            <input type="number" class="form-control" v-model="fastTesourariaTaxasAcordosGatewaySelecionado.percentual_cobranca_cartao">
          </label>
          <label class="col-4">
            <small>
              Taxa Boleto (em %):
            </small>
            <input type="number" class="form-control" v-model="fastTesourariaTaxasAcordosGatewaySelecionado.percentual_cobranca_boleto">
          </label>
        </div>
        <p class="text-secondary d-flex flex-column m-3">
          <small>- Digite apenas o número, não é necessário inserir o símbolo de %</small>
        </p>
        <div class="col-12 d-flex justify-content-center">
          <button v-if="$store.state.fastCarregando" class="btn btn-success py-0 px-2" @click="atualizaTaxasAcordosGateway" disabled>
            <small>
              <b-spinner
                type="grow"
                label="Spinning"
              />
            </small>
          </button>
          <button v-else-if="fastTesourariaTaxasAcordosGatewaySelecionado.id_taxa_acordo_gateway" class="btn btn-success py-0 px-2" @click="atualizaTaxasAcordosGateway">
            <small>
              Atualizar
            </small>
          </button>
          <button v-else class="btn btn-success py-0 px-2" @click="insereTaxasAcordosGateway">
            <small>
              Inserir
            </small>
          </button>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirParcela"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-9 pl-4 pr-4">
          <h4>Excluir parcelamento de {{ fastTesourariaGatewayPagamentoSelecionado.nome_gateway }}</h4>
        </div>
        <div class="col-3 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirParcela')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 m-2">
          <p>
            Deseja realmente excluir a parcela {{ fastTesourariaTaxasAcordosGatewaySelecionado.parcela }}?
          </p>
        </div>
        <div class="col-12 d-flex justify-content-center">
          <button v-if="$store.state.fastCarregando" class="btn btn-success py-0 px-2" @click="atualizaTaxasAcordosGateway" disabled>
            <small>
              <b-spinner
                type="grow"
                label="Spinning"
              />
            </small>
          </button>
          <button v-else class="btn btn-danger py-0 px-2" @click="excluiTaxasAcordosGateway">
            <small>
              Confirmar
            </small>
          </button>
        </div>
      </div>
    </modal>

    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoSecretariaPlanos",
  components: {},

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Plano
      fastSecretariaPlanoPagamento: [],
      fastSecretariaPlanoPagamentoLoading: true,
      fastSecretariaPlanoPagamentoNovo: {
        id_plataforma: 0,
        id_plano_pagamento: 0,
        nome_plano: "",
        ativo: false,
        obs: "",
        periocidade_cada_unidade: "1",
        dia_mes: "D",
        tempo_determinado: "0"
      },
      fastSecretariaPlanoPagamentoEditar: {
        id_plataforma: 0,
        id_plano_pagamento: 0,
        nome_plano: "",
        ativo: false,
        obs: "",
        periocidade_cada_unidade: "",
        dia_mes: "",
        tempo_determinado: "0"
      },
      // Tipo de pagamento
      fastSecretariaTipoPagamento: [],
      fastSecretariaFormaPagamentoArray: [],
      fastSecretariaFormaPagamentoOperador: "",
      fastSecretariaTipoPagamentoLoading: true,
      fastSecretariaFormaPagamentoLoading: true,
      fastSecretariaTipoPagamentoNovo: {
        id_tipo_pagamento: 0,
        id_plataforma: 0,
        nome_tipo_pagamento: "",
        operador: "",
        nomeOperador: "", 
        conta_contabil: "",
        forma_pagamento: "C",
        id_plano_conta: 0
      },
      fastSecretariaTipoPagamentoEditar: {
        id_tipo_pagamento: 0,
        id_plataforma: 0,
        nome_tipo_pagamento: "",
        operador: "",
        nomeOperador: "",
        conta_contabil: "",
        forma_pagamento: "",
        id_plano_conta: 0
      },
      // Conta
      fastTesourariaContaBanco: [],
      fastTesourariaContaBancoLoading: true,
      fastTesourariaContaBancoNovo: {
        id_conta_banco: 0,
        codigo_banco: "",
        agencia: "",
        id_plataforma: this.$route.params.id_plataforma,
        cnpj_banco: "",
        razao_banco: ""
      },
      fastTesourariaContaBancoEditar: {
        id_conta_banco: "",
        codigo_banco: "",
        agencia: "",
        id_plataforma: this.$route.params.id_plataforma,
        cnpj_banco: "",
        razao_banco: ""
      },
      // Plano de contas
      fastTesourariaPlanoContas: [],
      fastTesourariaPlanoContasLoading: [],
      fastTesourariaPlanoContaNovo: {
        id_plano_conta: 0,
        nome_conta: "",
        id_plano_conta_pai: 0,
        redutor: false,
        id_plataforma: this.$route.params.id_plataforma
      },
      fastTesourariaPlanoContaEditar: {
        id_plano_conta: 0,
        nome_conta: "",
        id_plano_conta_pai: 0,
        redutor: false,
        id_plataforma: this.$route.params.id_plataforma
      },
      // Centro de custo
      fastTesourariaCentoCusto: [],
      fastTesourariaCentoCustoLoading: [],
      fastTesourariaCentoCustoNovo: {
        id_cento_custo: 0,
        nome_cento_custo: "",
        id_plataforma: this.$route.params.id_plataforma
      },
      fastTesourariaCentoCustoEditar: {
        id_cento_custo: "",
        nome_cento_custo: "",
        id_plataforma: this.$route.params.id_plataforma
      },
      // Renegociacoes
      fastTesourariaGatewaysPagamento: [],
      fastTesourariaGatewayPagamentoSelecionado: {},
      fastTesourariaTaxasAcordos: [],
      fastTesourariaTaxasAcordosSelecionado: {
        id_plataforma: 0,
        id_pagamento_gateway: 0,
        taxa_juros_mensal: 0,
        multa: 0,
        taxa_serasa: 0,
        taxa_cartorio: 0,
        outras_taxas: 0,
        taxa_parceiro_cobranca: 0
      },
      fastTesourariaTaxasAcordosGateways: [],
      fastTesourariaTaxasAcordosGatewaySelecionado: {
        id_taxa_acordo_gateway: 0,
        id_plataforma: 0,
        id_pagamento_gateway: 0,
        parcela: 0,
        percentual_cobranca_cartao: 0,
        percentual_cobranca_boleto: 0
      }
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Planos
            this.getSecretariaPlanoPagamento(this.$route.params.id_plataforma);
            // Tipo de pagamento
            this.getSecretariaTipoPagamento(this.$route.params.id_plataforma);
            // Forma de pagamento
            this.getSecretariaFormaPagamento();
            // Contas
            this.getTesourariaContas(this.$route.params.id_plataforma);
            // Plano de contas
            this.getTesourariaPlanoContas(this.$route.params.id_plataforma);
            // Centros de custo
            this.getTesourariaCentoCusto(this.$route.params.id_plataforma);
            // Gateways de pagamento
            this.getGatewaysPagamento()
            
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    // Planos
    getSecretariaPlanoPagamento(id_plataforma){
      this.fastSecretariaPlanoPagamentoLoading = true
      this.promiseGetFastApi("api/fast_tesouraria_plano_pagamento/lista", "id_plataforma=" + id_plataforma).then(obj => {
        console.log("getSecretariaPlanoPagamento", obj);
        if (obj.length) {          
           obj.forEach(p => p.tempo_determinado = p.tempo_determinado.toString().trim())
          this.fastSecretariaPlanoPagamento = obj
        }
        else this.fastSecretariaPlanoPagamento = []

        this.fastSecretariaPlanoPagamentoLoading = false
      }).catch(e => {
        console.log(e);
        this.fastSecretariaPlanoPagamentoLoading = false
      })
    },
    async criarSecretariaPlanoPagamento() {
      this.fastSecretariaPlanoPagamentoNovo.id_plataforma = this.$route.params.id_plataforma;
      let erros = []

      this.fastSecretariaPlanoPagamentoNovo.ativo = this.fastSecretariaPlanoPagamentoNovo.ativo ? true : false

      if (!this.fastSecretariaPlanoPagamentoNovo.nome_plano) erros.push("O nome do plano é obrigatório")
      if (!this.fastSecretariaPlanoPagamentoNovo.obs) erros.push("O preenchimento das observações é obrigatório")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        console.log("fast_tesouraria_plano_pagamento", this.fastSecretariaPlanoPagamentoNovo)
        this.promiseInserirFastApi(this.fastSecretariaPlanoPagamentoNovo, "fast_tesouraria_plano_pagamento").then((res) => {
          console.log("criarSecretariaPlanoPagamento", res)
          this.exibeToasty("Plano criado com sucesso", "success");
          this.hideModal("modalCriarPlanoPagamento");
          this.fastSecretariaPlanoPagamentoNovo = {
            id_plataforma: 0,
            id_plano_pagamento: 0,
            nome_plano: "",
            ativo: "",
            obs: "",
            periocidade_cada_unidade: "1",
            dia_mes: "D",
            tempo_determinado: "0"
          }
          // Planos
          this.fastSecretariaPlanoPagamento = res;
        }).catch((e) => {
          this.exibeToasty("Erro ao criar requisito", "error");
        });
      }
    },
    exibeModalEditarPlanoPagamento(plano) {
      this.fastSecretariaPlanoPagamentoEditar = plano;
      console.log("this.fastSecretariaPlanoPagamentoEditar", this.fastSecretariaPlanoPagamentoEditar )
      this.showModal("modalEditarPlanoPagamento");
    },
    async editarPlanoPagamento() {
       this.$store.state.fastCarregando = true;
        this.promiseAtualizarFastApi(this.fastSecretariaPlanoPagamentoEditar, "fast_tesouraria_plano_pagamento").then(e => {
          this.exibeToasty("Salvo com sucesso", "success");
          this.$store.state.fastCarregando = false;
          this.hideModal("modalEditarPlanoPagamento");
        }).catch(e => {
          this.exibeToasty("Erro ao salvar alterações", "error");
          this.$store.state.fastCarregando = false;
        }
        );
    },
    exibeModalExcluirPlanoPagamento(plano) {
      this.fastSecretariaPlanoPagamentoEditar = plano;
      this.showModal("modalExcluirPlanoPagamento");
    },
    async excluirPlanoPagamento(){
      this.promiseExcluirFastApi(this.fastSecretariaPlanoPagamentoEditar, "fast_tesouraria_plano_pagamento").then(e => {
        this.exibeToasty("Plano excluído com sucesso", "success");        
        this.getSecretariaPlanoPagamento(this.$route.params.id_plataforma);   
        this.hideModal("modalExcluirPlanoPagamento");
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.$store.state.fastCarregando = false;
      });
    },
    retornaMes(n) {
      switch (n) {
        case "1":
          return "Janeiro"
          break;
        case "2":
          return "Fevereiro"
          break;
        case "3":
          return "Março"
          break;
        case "4":
          return "Abril"
          break;
        case "5":
          return "Maio"
          break;
        case "6":
          return "Junho"
          break;
        case "7":
          return "Julho"
          break;
        case "8":
          return "Agosto"
          break;
        case "9":
          return "Setembro"
          break;
        case "10":
          return "Outubro"
          break;
        case "11":
          return "Novembro"
          break;
        case "12":
          return "Dezembro"
          break;      
        default:
          break;
      }
    },
    resetaPeriodicidadeTempo(){
      this.fastSecretariaPlanoPagamentoNovo.periocidade_cada_unidade = 1
      this.fastSecretariaPlanoPagamentoNovo.tempo_determinado = '1'
      this.fastSecretariaPlanoPagamentoEditar.periocidade_cada_unidade = 1
      this.fastSecretariaPlanoPagamentoEditar.tempo_determinado = '1'
    },
    // Tipo pagamento
    getSecretariaTipoPagamento(id_plataforma){
      this.fastSecretariaTipoPagamentoLoading = true
      this.promiseGetFastApi("api/fast_tesouraria_tipo_pagamento/lista", "id_plataforma=" + id_plataforma).then(obj => {
        console.log("getSecretariaTipoPagamento", obj);
        if (obj.length) this.fastSecretariaTipoPagamento = obj;
        else this.fastSecretariaTipoPagamento = []

        this.fastSecretariaTipoPagamentoLoading = false
      }).catch(e => {
        console.log(e);
        this.fastSecretariaTipoPagamentoLoading = false
      })
    },
    async getSecretariaFormaPagamento(){
      this.fastSecretariaFormaPagamentoLoading = true
      this.promiseGetFastApi("api/fast_tesouraria_forma_pagamento/lista").then(obj => {
        console.log("getSecretariaFormaPagamento", obj);
        if (obj.length) this.fastSecretariaFormaPagamentoArray = obj;
        this.fastSecretariaFormaPagamentoLoading = false
      }).catch(e => {
        console.log(e);
        this.fastSecretariaFormaPagamentoLoading = false
      })
    },
    async criarSecretariaTipoPagamento() {
      this.fastSecretariaTipoPagamentoNovo.id_plataforma = this.$route.params.id_plataforma
      this.fastSecretariaTipoPagamentoNovo.id_plano_conta = this.fastSecretariaTipoPagamentoNovo.id_plano_conta ? this.fastSecretariaTipoPagamentoNovo.id_plano_conta : 0
      let erros = []
      if (!this.fastSecretariaTipoPagamentoNovo.nome_tipo_pagamento) erros.push("O nome do tipo de pagamento é obrigatório")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        console.log("fast_tesouraria_tipo_pagamento", this.fastSecretariaTipoPagamentoNovo)
        this.promiseInserirFastApi(this.fastSecretariaTipoPagamentoNovo, "fast_tesouraria_tipo_pagamento").then((res) => {
          console.log("criarSecretariaTipoPagamento", res)
          this.exibeToasty("Tipo de pagamento criado com sucesso", "success");
          this.hideModal("modalCriarTipoPagamento");
          this.fastSecretariaTipoPagamentoNovo = {
            id_tipo_pagamento: 0,
            id_plataforma: 0,
            nome_tipo_pagamento: "",
            operador: "",
            nomeOperador: "",
            forma_pagamento: "",
            id_plano_conta: 0

          }
          // Tipos de pagamento
          this.fastSecretariaTipoPagamento = res;
        }).catch((e) => {
          this.exibeToasty("Erro ao criar requisito", "error");
        });
      }
    },
    modificaOperador(){
      const operador = this.selecionaOperador();
      if (operador) {
        this.fastSecretariaTipoPagamentoNovo.operador = operador.operador;
        this.fastSecretariaTipoPagamentoNovo.nomeOperador = operador.nomeOperador;
      }    
    },
    selecionaOperador() {
      const operador = this.fastSecretariaFormaPagamentoArray.find(o => o.forma_pagamento_sigla === this.fastSecretariaTipoPagamentoNovo.forma_pagamento);
      if (operador) {
        if (operador.operador === "D") {
          return { operador: operador.operador, nomeOperador: "Débito" };
        } else if(operador.operador === "C") {
          return { operador: operador.operador, nomeOperador: "Crédito" };
        } else {
          return { operador: operador.operador, nomeOperador: "Qualquer operador" };
        }
      }
    },
    modificaOperadorEditar(){
      const operador = this.selecionaOperadorEditar();
      if (operador) {
        this.fastSecretariaTipoPagamentoEditar.operador = operador.operador;
        this.fastSecretariaTipoPagamentoEditar.nomeOperador = operador.nomeOperador;
      }    
    },
    selecionaOperadorEditar() {
      const operador = this.fastSecretariaFormaPagamentoArray.find(o => o.forma_pagamento_sigla === this.fastSecretariaTipoPagamentoEditar.forma_pagamento);
      if (operador) {
        if (operador.operador === "D") {
          return { operador: operador.operador, nomeOperador: "Débito" };
        } else if(operador.operador === "C") {
          return { operador: operador.operador, nomeOperador: "Crédito" };
        } else {
          return { operador: operador.operador, nomeOperador: "Qualquer operador" };
        }
      }
    },
    exibeModalEditarTipoPagamento(pagamento) {
      if (!pagamento.id_plano_conta) pagamento.id_plano_conta = 0
      console.log("poagamento", pagamento);
      this.fastSecretariaTipoPagamentoEditar = pagamento;
      this.modificaOperadorEditar()
      this.showModal("modalEditarTipoPagamento");
    },
    async editarTipoPagamento() {
       this.$store.state.fastCarregando = true;
       this.fastSecretariaTipoPagamentoEditar.id_plano_conta = this.fastSecretariaTipoPagamentoEditar.id_plano_conta ? this.fastSecretariaTipoPagamentoEditar.id_plano_conta : 0
        this.promiseAtualizarFastApi(this.fastSecretariaTipoPagamentoEditar, "fast_tesouraria_tipo_pagamento").then(e => {
          this.exibeToasty("Salvo com sucesso", "success");
          this.fastSecretariaTipoPagamentoEditar = {
            id_tipo_pagamento: 0,
            id_plataforma: 0,
            nome_tipo_pagamento: "",
            operador: "",
            nomeOperador: "",
            forma_pagamento: "",
            id_plano_conta: 0

          }
          this.$store.state.fastCarregando = false;
          this.hideModal("modalEditarTipoPagamento");
        }).catch(e => {
          this.exibeToasty("Erro ao salvar alterações", "error");
          this.$store.state.fastCarregando = false;
        }
        );
    },
    exibeModalExcluirTipoPagamento(pagamento) {
      this.fastSecretariaTipoPagamentoEditar = pagamento;
      this.showModal("modalExcluirTipoPagamento");
    },
    async excluirTipoPagamento(){
      this.promiseExcluirFastApi(this.fastSecretariaTipoPagamentoEditar, "fast_tesouraria_tipo_pagamento").then(e => {
        this.exibeToasty("Tipo de pagamento excluído com sucesso", "success");        
        this.getSecretariaTipoPagamento(this.$route.params.id_plataforma);   
        this.hideModal("modalExcluirTipoPagamento");
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.$store.state.fastCarregando = false;
      });
    },
    // Conta
    async getTesourariaContas(id_plataforma){
      this.fastTesourariaContaBancoLoading = true
      this.promiseGetFastApi("api/fast_tesouraria_conta_banco/lista", "id_plataforma=" + id_plataforma).then(obj => {
        console.log("getTesourariaContas", obj);
        if (obj.length) this.fastTesourariaContaBanco = obj;
        else this.fastTesourariaContaBanco = []

        this.fastTesourariaContaBancoLoading = false
      }).catch(e => {
        console.log(e);
        this.fastTesourariaContaBancoLoading = false
      })
    },
    async criarTesourariaContaBanco() {
      this.fastTesourariaContaBancoNovo.id_plataforma = this.$route.params.id_plataforma;
      let erros = []
      if (!this.fastTesourariaContaBancoNovo.codigo_banco) erros.push("O código do banco é obrigatório")
      if (!this.fastTesourariaContaBancoNovo.agencia) erros.push("A agência é obrigatória")
      if (!this.fastTesourariaContaBancoNovo.cnpj_banco) erros.push("O CNPJ do banco é obrigatório")
      if (!this.fastTesourariaContaBancoNovo.razao_banco) erros.push("A razão do banco é obrigatória")
      
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        console.log("fastTesourariaContaBancoNovo", this.fastTesourariaContaBancoNovo)
        this.promiseInserirFastApi(this.fastTesourariaContaBancoNovo, "fast_tesouraria_conta_banco").then((res) => {
          console.log("criarTesourariaContaBanco", res)
          if (res.length) {
            this.exibeToasty("Conta cadastrada com sucesso", "success");
            this.hideModal("modalCriarContaBanco");
            this.fastTesourariaContaBancoNovo = {
              id_conta_banco: "",
              codigo_banco: "",
              agencia: "",
              id_plataforma: this.$route.params.id_plataforma,
              cnpj_banco: "",
              razao_banco: ""
            }
            this.fastTesourariaContaBanco.push(res[0]);
          } else {
            this.exibeToasty("Erro ao criar requisito", "error")
          }         
        }).catch((e) => {
          this.exibeToasty("Erro ao criar requisito", "error");
        });
      }
    },
    exibeModalEditarContaBanco(conta) {
      this.fastTesourariaContaBancoEditar = conta;
      this.showModal("modalEditarContaBanco");
    },
    async editarContaBanco() {
       this.$store.state.fastCarregando = true;
        this.promiseAtualizarFastApi(this.fastTesourariaContaBancoEditar, "fast_tesouraria_conta_banco").then(e => {
          this.exibeToasty("Salvo com sucesso", "success");
          this.$store.state.fastCarregando = false;
          this.hideModal("modalEditarContaBanco");
        }).catch(e => {
          this.exibeToasty("Erro ao salvar alterações", "error");
          this.$store.state.fastCarregando = false;
        }
        );
    },
    exibeModalExcluirContaBanco(conta) {
      this.fastTesourariaContaBancoEditar = conta;
      this.showModal("modalExcluirContaBanco");
    },
    async excluirContaBanco(){
      this.promiseExcluirFastApi(this.fastTesourariaContaBancoEditar, "fast_tesouraria_conta_banco").then(res => {
        if (res.length < this.fastTesourariaContaBanco.length) {
          this.exibeToasty("Conta excluída com sucesso", "success")
          this.hideModal("modalExcluirContaBanco")
          this.fastTesourariaContaBanco = res
        } else {
          this.exibeToasty("Erro ao excluir", "error")
        }
        
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error")
        this.$store.state.fastCarregando = false;
      });
    },
    // Plano de contas
    async getTesourariaPlanoContas(id_plataforma){
      this.fastTesourariaPlanoContasLoading = true
      this.promiseGetFastApi("api/fast_tesouraria_plano_contas/lista", "id_plataforma=" + id_plataforma).then(obj => {
        console.log("getTesourariaPlanoContas", obj);
        if (obj.length) this.fastTesourariaPlanoContas = obj;
        else this.fastTesourariaPlanoContas = []

        this.fastTesourariaPlanoContasLoading = false
      }).catch(e => {
        console.log(e);
        this.fastTesourariaPlanoContasLoading = false
      })
    },
    async criarTesourariaPlanoConta() {
      this.fastTesourariaPlanoContaNovo.id_plataforma = this.$route.params.id_plataforma;
      let erros = []
      if (!this.fastTesourariaPlanoContaNovo.nome_conta) erros.push("O nome da conta é obrigatória")
      
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        console.log("fastTesourariaPlanoContaNovo", this.fastTesourariaPlanoContaNovo)
        this.promiseInserirFastApi(this.fastTesourariaPlanoContaNovo, "fast_tesouraria_plano_contas").then((res) => {
          console.log("criarTesourariaPlanoConta", res)
          if (res.length) {
            this.exibeToasty("Plano de conta cadastrada com sucesso", "success");
            this.hideModal("modalCriarPlanoConta");
            this.fastTesourariaPlanoContaNovo = {
              id_plano_conta: "",
              nome_conta: "",
              id_plano_conta_pai: 0,
              redutor: false,
              id_plataforma: this.$route.params.id_plataforma
            }
            this.fastTesourariaPlanoContas.push(res[0]);
          } else {
            this.exibeToasty("Erro ao criar plano de conta", "error")
          }         
        }).catch((e) => {
          this.exibeToasty("Erro ao criar plano de conta", "error");
        });
      }
    },
    exibeModalEditarPlanoConta(conta) {
      this.fastTesourariaPlanoContaEditar = conta;
      this.showModal("modalEditarPlanoConta");
    },
    async editarPlanoConta() {
       this.$store.state.fastCarregando = true;
        this.promiseAtualizarFastApi(this.fastTesourariaPlanoContaEditar, "fast_tesouraria_plano_contas").then(e => {
          this.exibeToasty("Salvo com sucesso", "success");
          this.$store.state.fastCarregando = false;
          this.hideModal("modalEditarPlanoConta");
        }).catch(e => {
          this.exibeToasty("Erro ao salvar alterações", "error");
          this.$store.state.fastCarregando = false;
        }
        );
    },
    exibeModalExcluirPlanoConta(conta) {
      this.fastTesourariaPlanoContaEditar = conta;
      this.showModal("modalExcluirPlanoConta");
    },
    async excluirPlanoConta(){
      this.promiseExcluirFastApi(this.fastTesourariaPlanoContaEditar, "fast_tesouraria_plano_contas").then(res => {
        if (res.length < this.fastTesourariaPlanoContas.length) {
          this.exibeToasty("Conta excluída com sucesso", "success")
          this.hideModal("modalExcluirPlanoConta")
          this.fastTesourariaPlanoContas = res
        } else {
          this.exibeToasty("Erro ao excluir", "error")
        }
        
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error")
        this.$store.state.fastCarregando = false;
      });
    },
    // Centro de custo
    async getTesourariaCentoCusto(id_plataforma){
      this.fastTesourariaCentoCustoLoading = true
      this.promiseGetFastApi("api/fast_tesouraria_cento_custo/lista", "id_plataforma=" + id_plataforma).then(obj => {
        console.log("getTesourariaCentoCusto", obj);
        if (obj.length) this.fastTesourariaCentoCusto = obj;
        else this.fastTesourariaCentoCusto = []

        this.fastTesourariaCentoCustoLoading = false
      }).catch(e => {
        console.log(e);
        this.fastTesourariaCentoCustoLoading = false
      })
    },
    async criarTesourariaCentoCusto() {
      this.fastTesourariaCentoCustoNovo.id_plataforma = this.$route.params.id_plataforma;
      let erros = []
      if (!this.fastTesourariaCentoCustoNovo.nome_cento_custo) erros.push("O nome é obrigatório")
      
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        console.log("fastTesourariaCentoCustoNovo", this.fastTesourariaCentoCustoNovo)
        this.promiseInserirFastApi(this.fastTesourariaCentoCustoNovo, "fast_tesouraria_cento_custo").then((res) => {
          console.log("criarTesourariaCentoCusto", res)
          if (res.length) {
            this.exibeToasty("Centro de custo cadastrado com sucesso", "success");
            this.hideModal("modalCriarCentoCusto");
            this.fastTesourariaCentoCustoNovo = {
              id_cento_custo: "",
              nome_cento_custo: "",
              id_plataforma: this.$route.params.id_plataforma
            }
            this.fastTesourariaCentoCusto.push(res[0]);
          } else {
            this.exibeToasty("Erro ao criar centro de custo", "error")
          }         
        }).catch((e) => {
          this.exibeToasty("Erro ao criar centro de custo", "error");
        });
      }
    },
    exibeModalEditarCentoCusto(obj) {
      this.fastTesourariaCentoCustoEditar = obj;
      this.showModal("modalEditarCentoCusto");
    },
    async editarCentoCusto() {
       this.$store.state.fastCarregando = true;
        this.promiseAtualizarFastApi(this.fastTesourariaCentoCustoEditar, "fast_tesouraria_cento_custo").then(e => {
          this.exibeToasty("Salvo com sucesso", "success");
          this.$store.state.fastCarregando = false;
          this.hideModal("modalEditarCentoCusto");
        }).catch(e => {
          this.exibeToasty("Erro ao salvar alterações", "error");
          this.$store.state.fastCarregando = false;
        }
        );
    },
    exibeModalExcluirCentoCusto(obj) {
      this.fastTesourariaCentoCustoEditar = obj;
      this.showModal("modalExcluirCentoCusto");
    },
    async excluirCentoCusto(){
      this.promiseExcluirFastApi(this.fastTesourariaCentoCustoEditar, "fast_tesouraria_cento_custo").then(res => {
        if (res.length < this.fastTesourariaCentoCusto.length) {
          this.exibeToasty("Conta excluída com sucesso", "success")
          this.hideModal("modalExcluirCentoCusto")
          this.fastTesourariaCentoCusto = res
        } else {
          this.exibeToasty("Erro ao excluir", "error")
        }
        
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error")
        this.$store.state.fastCarregando = false;
      });
    },
    async getGatewaysPagamento(){
      this.$store.state.fastCarregando = true;
      try {
        this.promiseGetFastApi("api/fast_pagamento_gateway/lista_disponiveis", `id_plataforma=${this.$route.params.id_plataforma}`).then(res => {
          if(res.success === false) {
            this.exibeToasty(`${res.error} ${res.description}`, "error")
            console.log(`${res.error} ${res.description}`)
          } else {
            this.fastTesourariaGatewaysPagamento = res
          }
        }).catch(e => {
          console.error(e);
          this.exibeToasty("Ocorreu um erro", "error")
        })
        this.$store.state.fastCarregando = false;
      } catch (error) {
        console.error(error);
        this.exibeToasty("Erro ao processar solicitação", "error")
        this.$store.state.fastCarregando = false;
      }
    },
    async getTaxasAcordos(id_pagamento_gateway) {
      this.$store.state.fastCarregando = true;
      try {
        this.promiseGetFastApi("api/fast_tesouraria_taxas_acordo/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_pagamento_gateway=${id_pagamento_gateway}`).then(res => {
          if(res.success === false) {
            this.exibeToasty(`${res.error} ${res.description}`, "error")
            console.log(`${res.error} ${res.description}`)
          } else {
            this.fastTesourariaTaxasAcordos = res
            if (res.length) {
              this.fastTesourariaTaxasAcordosSelecionado = res[0]
            }
          }
        }).catch(e => {
          console.error(e);
          this.exibeToasty("Ocorreu um erro", "error")
        })
        this.$store.state.fastCarregando = false;
      } catch (error) {
        console.error(error);
        this.exibeToasty("Erro ao processar solicitação", "error")
        this.$store.state.fastCarregando = false;
      }
    },
    async getTaxasAcordosGateways(id_pagamento_gateway) {
      this.$store.state.fastCarregando = true;
      try {
        this.promiseGetFastApi("api/fast_tesouraria_taxas_acordo_gateway/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_pagamento_gateway=${id_pagamento_gateway}`).then(res => {
          if(res.success === false) {
            this.exibeToasty(`${res.error} ${res.description}`, "error")
            console.log(`${res.error} ${res.description}`)
          } else {
            this.fastTesourariaTaxasAcordosGateways = res
          }
        }).catch(e => {
          console.error(e);
          this.exibeToasty("Ocorreu um erro", "error")
        })
        this.$store.state.fastCarregando = false;
      } catch (error) {
        console.error(error);
        this.exibeToasty("Erro ao processar solicitação", "error")
        this.$store.state.fastCarregando = false;
      }
    },
    limpaObjTaxasAcordosSelecionado(){
      this.fastTesourariaTaxasAcordosSelecionado.id_plataforma = '';
      this.fastTesourariaTaxasAcordosSelecionado.id_pagamento_gateway = '';
      this.fastTesourariaTaxasAcordosSelecionado.taxa_juros_mensal = '';
      this.fastTesourariaTaxasAcordosSelecionado.multa = '';
      this.fastTesourariaTaxasAcordosSelecionado.taxa_serasa = '';
      this.fastTesourariaTaxasAcordosSelecionado.taxa_cartorio = '';
      this.fastTesourariaTaxasAcordosSelecionado.outras_taxas = '';
      this.fastTesourariaTaxasAcordosSelecionado.taxa_parceiro_cobranca = '';
    },
    limpaObjTaxasAcordosGatewaySelecionado(){
      this.fastTesourariaTaxasAcordosGatewaySelecionado.id_taxa_acordo_gateway = '';
      this.fastTesourariaTaxasAcordosGatewaySelecionado.id_plataforma = '';
      this.fastTesourariaTaxasAcordosGatewaySelecionado.id_pagamento_gateway = '';
      this.fastTesourariaTaxasAcordosGatewaySelecionado.parcela = '';
      this.fastTesourariaTaxasAcordosGatewaySelecionado.percentual_cobranca_cartao = '';
      this.fastTesourariaTaxasAcordosGatewaySelecionado.percentual_cobranca_boleto = '';
    },
    exibeModalConfigurarTaxasEAcordos(gateway){
      this.$store.state.fastCarregando = true;
      this.limpaObjTaxasAcordosSelecionado()
      this.fastTesourariaGatewayPagamentoSelecionado = gateway
      this.getTaxasAcordos(this.fastTesourariaGatewayPagamentoSelecionado.id_pagamento_gateway)
      this.$store.state.fastCarregando = false;
      this.showModal('modalConfigurarTaxasEAcordos')
    },
    exibeModalExcluirTaxasEAcordos(){
      this.hideModal('modalConfigurarTaxasEAcordos')
      this.showModal('modalExcluirTaxasAcordos')
    },
    exibeModalConfigurarOpcoesDeParcelamento(gateway){
      this.$store.state.fastCarregando = true;
      this.limpaObjTaxasAcordosSelecionado()
      this.fastTesourariaGatewayPagamentoSelecionado = gateway
      this.getTaxasAcordosGateways(this.fastTesourariaGatewayPagamentoSelecionado.id_pagamento_gateway)
      this.$store.state.fastCarregando = false;
      this.showModal('modalConfigurarOpcoesDeParcelamento')
    },
    exibeModalConfigurarParcela(metodo, obj){
      this.$store.state.fastCarregando = true;
      this.limpaObjTaxasAcordosGatewaySelecionado()
      if (metodo === 'editar') {
        this.fastTesourariaTaxasAcordosGatewaySelecionado = obj
      }
      this.$store.state.fastCarregando = false;
      this.hideModal('modalConfigurarOpcoesDeParcelamento')
      this.showModal('modalConfigurarParcela')
    },
    exibeModalExcluirParcela(obj){
      this.$store.state.fastCarregando = true;
      this.limpaObjTaxasAcordosGatewaySelecionado()
      this.fastTesourariaGatewayPagamentoSelecionado.id_pagamento_gateway = obj.id_pagamento_gateway
      this.fastTesourariaTaxasAcordosGatewaySelecionado.parcela = obj.parcela
      this.$store.state.fastCarregando = false;
      this.hideModal('modalConfigurarOpcoesDeParcelamento')
      this.showModal('modalExcluirParcela')
    },
    async insereTaxasAcordos() {
      this.$store.state.fastCarregando = true;
      const obj = {
        id_plataforma: this.$route.params.id_plataforma,
        id_pagamento_gateway: this.fastTesourariaGatewayPagamentoSelecionado.id_pagamento_gateway,
        taxa_juros_mensal: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosSelecionado.taxa_juros_mensal),
        multa: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosSelecionado.multa),
        taxa_serasa: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosSelecionado.taxa_serasa),
        taxa_cartorio: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosSelecionado.taxa_cartorio),
        outras_taxas: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosSelecionado.outras_taxas),
        taxa_parceiro_cobranca: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosSelecionado.taxa_parceiro_cobranca)
      }
      try {
        this.promiseInserirFastApi(obj, "fast_tesouraria_taxas_acordo").then(res => {
          if(res.success === false) {
            this.exibeToasty(`${res.error} ${res.description}`, "error")
            console.log(`${res.error} ${res.description}`)
          } else {
            this.exibeToasty(`Alterações salvas`, "success")
            this.hideModal('modalConfigurarTaxasEAcordos')
          }
        }).catch(e => {
          console.error(e);
          this.exibeToasty("Ocorreu um erro", "error")
        })
        this.$store.state.fastCarregando = false;
      } catch (error) {
        console.error(error);
        this.exibeToasty("Erro ao processar solicitação", "error")
        this.$store.state.fastCarregando = false;
      }
    },
    async atualizaTaxasAcordos() {
      this.$store.state.fastCarregando = true;
      const obj = {
        id_plataforma: this.$route.params.id_plataforma,
        id_pagamento_gateway: this.fastTesourariaGatewayPagamentoSelecionado.id_pagamento_gateway,
        taxa_juros_mensal: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosSelecionado.taxa_juros_mensal),
        multa: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosSelecionado.multa),
        taxa_serasa: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosSelecionado.taxa_serasa),
        taxa_cartorio: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosSelecionado.taxa_cartorio),
        outras_taxas: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosSelecionado.outras_taxas),
        taxa_parceiro_cobranca: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosSelecionado.taxa_parceiro_cobranca)
      }
      try {
        this.promiseAtualizarFastApi(obj, "fast_tesouraria_taxas_acordo").then(res => {
          if(res.success === false) {
            this.exibeToasty(`${res.error} ${res.description}`, "error")
            console.log(`${res.error} ${res.description}`)
          } else {
            this.exibeToasty(`Alterações salvas`, "success")
            this.hideModal('modalConfigurarTaxasEAcordos')
          }
        }).catch(e => {
          console.error(e);
          this.exibeToasty("Ocorreu um erro", "error")
        })
        this.$store.state.fastCarregando = false;
      } catch (error) {
        console.error(error);
        this.exibeToasty("Erro ao processar solicitação", "error")
        this.$store.state.fastCarregando = false;
      }
    },
    async excluiTaxasAcordos() {
      this.$store.state.fastCarregando = true;
      const obj = {
        id_plataforma: this.$route.params.id_plataforma,
        id_pagamento_gateway: this.fastTesourariaGatewayPagamentoSelecionado.id_pagamento_gateway,
      }
      try {
        this.promiseExcluirFastApi(obj, "fast_tesouraria_taxas_acordo").then(res => {
          if(res.success === false) {
            this.exibeToasty(`${res.error} ${res.description}`, "error")
            console.log(`${res.error} ${res.description}`)
          } else {
            this.exibeToasty(`Alterações salvas`, "success")
            this.hideModal('modalExcluirTaxasAcordos')
          }
        }).catch(e => {
          console.error(e);
          this.exibeToasty("Ocorreu um erro", "error")
        })
        this.$store.state.fastCarregando = false;
      } catch (error) {
        console.error(error);
        this.exibeToasty("Erro ao processar solicitação", "error")
        this.$store.state.fastCarregando = false;
      }
    },
    async insereTaxasAcordosGateway() {
      this.$store.state.fastCarregando = true;
      const obj = {
          id_plataforma: this.$route.params.id_plataforma,
          id_pagamento_gateway: this.fastTesourariaGatewayPagamentoSelecionado.id_pagamento_gateway,
          parcela: +this.fastTesourariaTaxasAcordosGatewaySelecionado.parcela,
          percentual_cobranca_cartao: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosGatewaySelecionado.percentual_cobranca_cartao),
          percentual_cobranca_boleto: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosGatewaySelecionado.percentual_cobranca_boleto)
      }
      try {
        this.promiseInserirFastApi(obj, "fast_tesouraria_taxas_acordo_gateway").then(res => {
          if(res.success === true) {
            this.exibeToasty(`Alterações salvas`, "success")
            this.hideModal('modalConfigurarParcela')
            this.exibeModalConfigurarOpcoesDeParcelamento(this.fastTesourariaGatewayPagamentoSelecionado)
          } else {
            this.exibeToasty(`${res.error} ${res.description}`, "error")
            console.log(`${res.error} ${res.description}`)
          }
        }).catch(e => {
          console.error(e);
          this.exibeToasty("Ocorreu um erro", "error")
        })
        this.$store.state.fastCarregando = false;
      } catch (error) {
        console.error(error);
        this.exibeToasty("Erro ao processar solicitação", "error")
        this.$store.state.fastCarregando = false;
      }
    },
    async atualizaTaxasAcordosGateway() {
      this.$store.state.fastCarregando = true;
      const obj = {
          id_taxa_acordo_gateway: this.fastTesourariaTaxasAcordosGatewaySelecionado.id_taxa_acordo_gateway,
          id_plataforma: this.$route.params.id_plataforma,
          id_pagamento_gateway: this.fastTesourariaGatewayPagamentoSelecionado.id_pagamento_gateway,
          parcela: +this.fastTesourariaTaxasAcordosGatewaySelecionado.parcela,
          percentual_cobranca_cartao: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosGatewaySelecionado.percentual_cobranca_cartao),
          percentual_cobranca_boleto: +this.formataInputTaxa(this.fastTesourariaTaxasAcordosGatewaySelecionado.percentual_cobranca_boleto)
      }
      try {
        this.promiseAtualizarFastApi(obj, "fast_tesouraria_taxas_acordo_gateway").then(res => {
          if(res.success === false) {
            this.exibeToasty(`${res.error} ${res.description}`, "error")
            console.log(`${res.error} ${res.description}`)
          } else {
            this.exibeToasty(`Alterações salvas`, "success")
            this.hideModal('modalConfigurarParcela')
            this.exibeModalConfigurarOpcoesDeParcelamento(this.fastTesourariaGatewayPagamentoSelecionado)
          }
        }).catch(e => {
          console.error(e);
          this.exibeToasty("Ocorreu um erro", "error")
        })
        this.$store.state.fastCarregando = false;
      } catch (error) {
        console.error(error);
        this.exibeToasty("Erro ao processar solicitação", "error")
        this.$store.state.fastCarregando = false;
      }
    },
    async excluiTaxasAcordosGateway() {
      this.$store.state.fastCarregando = true;
      const obj = {
          id_plataforma: this.$route.params.id_plataforma,
          id_pagamento_gateway: this.fastTesourariaGatewayPagamentoSelecionado.id_pagamento_gateway,
          parcela: +this.fastTesourariaTaxasAcordosGatewaySelecionado.parcela,
      }
      try {
        this.promiseExcluirFastApi(obj, "fast_tesouraria_taxas_acordo_gateway").then(res => {
          if(res.success === false) {
            this.exibeToasty(`${res.error} ${res.description}`, "error")
            console.log(`${res.error} ${res.description}`)
          } else {
            this.exibeToasty(`Alterações salvas`, "success")
            this.hideModal('modalExcluirParcela')
            this.exibeModalConfigurarOpcoesDeParcelamento(this.fastTesourariaGatewayPagamentoSelecionado)
          }
        }).catch(e => {
          console.error(e);
          this.exibeToasty("Ocorreu um erro", "error")
        })
        this.$store.state.fastCarregando = false;
      } catch (error) {
        console.error(error);
        this.exibeToasty("Erro ao processar solicitação", "error")
        this.$store.state.fastCarregando = false;
      }
    },
    formataInputTaxa(valor){
      let input = valor.toString().replace(/,/g, '.').trim()
      return input
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}

.icon-update,
.icon-remove {
  cursor: pointer;
  transition: .5s;
  font-size: 2em;
  padding: 5px;
  border-radius: 50%;
}
.icon-update:hover,
.icon-remove:hover {
  color: white !important;
}
.icon-update:hover{
  background-color: blue;
}
.icon-remove:hover {
  background-color: red;
}
</style>
